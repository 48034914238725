import React, { useState,useEffect } from 'react';
import './css/questions.css';
import MdImage from './images/mediumimage.webp';
import SmImage from './images/smallimage.webp';
import LgImage from './images/lgimage.webp';
import Logo from './images/logo.png';
import { Link as RouterLink } from 'react-router-dom';



import AOS from 'aos';
import 'aos/dist/aos.css';



const questionsData = [
{
    title: "What is Meister's primary focus in the construction industry?",
    answer: "Meister is dedicated to providing innovative and sustainable construction chemicals to meet the needs of its clients.",
},
{
    title: "What sets Meister apart from its competitors?",
    answer: "Meister's commitment to quality, creative solutions, and continuous improvement distinguishes it from competitors in the industry.",
},

{
    title: "What is the significance of the term Meister in the company's name?",
    answer: "Meister means master, and Meister aims to guarantee mastery in production, safety, working conditions, and delivery in its field.",
},

{
    title: "Who is the parent company of Meister, and what is its expertise?",
    answer: "Meister is a subsidiary of Batterjee Holding Company, which specializes in pharmaceuticals, medical equipment, baby products, foods, plastics, and more.",
},

{
    title: "Are Meister's construction materials compliant with international quality standards?",
    answer: "Yes, Meister adheres to stringent international standards of quality and production, and its materials are comparable to competitors worldwide.",
},


];

function Questions() {
const [activeQuestion, setActiveQuestion] = useState(null);

const toggleQuestion = (index) => {
    if (index === activeQuestion) {
    // Clicked the active question again, so close it.
    setActiveQuestion(null);
    } else {
    // Clicked on a different question, open it.
    setActiveQuestion(index);
    }
};
useEffect(() => {
    AOS.init({
        duration: 700,
    });
}, [])

return (
    
    <>
    <section className='questions'>
        <div className='container-fluid'>
        <div className='row'>
            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
            <div className='questions-image'>
                <div className='top'>
                <img data-aos="fade-left" src={MdImage} alt="question mid image" />
                <img  data-aos="fade-left" src={SmImage} alt="question small image" />
                </div>
                <div data-aos="fade-left" className='bottom'>
                <img src={LgImage} alt="questions large image" />
                </div>

            </div>
            </div>

            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
            <div className='questions-text'>
                <h2 data-aos="fade-right">Commonly Asked Questions</h2>
                <h1 data-aos="fade-right">We've brought together a <span> top-notch </span> team to <span> collaborate </span> with you.</h1>

                {questionsData.map((question, index) => (
                <div
                    className={`question ${index === activeQuestion ? 'active' : ''}`}
                    key={index}
                    onClick={() => toggleQuestion(index)}
                >
                    <h3>{question.title}</h3>
                    <p>{question.answer}</p>
                </div>
                ))}

                <RouterLink data-aos="fade-right" to="/our-projects">
                See All Projects
                <i className="las la-arrow-right"></i>
                </RouterLink>
            </div>
            </div>
        </div>
        </div>
    </section>
    </>
);
}

export default Questions;
