import './css/contactus.css';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation from react-router-dom


import ContactUsBack from './images/pageheaders/becomeadistrbuter.webp';

import PageHeader from './PageHeader';

function BecomeADistrbuter(){

    const location = useLocation(); // Get the current location from react-router

    useEffect(() => {
    // Scroll to the top of the page whenever the route changes
    window.scrollTo(0, 0);
    }, [location.pathname]); // Re-run this effect when the pathname changes

    return(
        <>
            <PageHeader className="special" link1="HOME" link2="BECOME A DISTRBUTER"  title="BECOME A DISTRBUTER" img={ContactUsBack}/>

            <section className='contact-us become-dist'>
                <div className='container-fluid'>
                    <div className='row'>


                        <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                            <div className='form-content'>
                                <form>
                                    <div className='inputs'>
                                        <div className='input'>
                                            <input required type="text" placeholder='Company name *' />
                                        </div>

                                        <div className='input'>
                                            <input required type="email" placeholder='Company email *' />
                                        </div>
                                    </div>

                                    <div className='inputs'>
                                        <div className='input'>
                                            <input required type="text" placeholder='Name of the contact person  *' />
                                        </div>

                                        <div className='input'>
                                            <input required type="email" placeholder='Email of the contact person  *' />
                                        </div>
                                    </div>

                                    
                                    <div className='inputs'>
                                        <div className='input'>
                                            <input required type="tel" placeholder='Phone number of the contact person *' />
                                        </div>

                                        <div className='input'>
                                            <input required type="text" placeholder='Activity of the company *' />
                                        </div>
                                    </div>


                                    <div className='inputs'>
                                        <div className='input'>
                                            <input required type="text" placeholder='The regional area of the company’s activity *' />
                                        </div>
                                        
                                        <div className='input'>
                                            <input required type="text" placeholder='Previous or current agencies *' />
                                        </div>
                                    </div>


                                    <div className='inputs'>
                                        <div className='input'>
                                        <textarea required placeholder='Summary text *'></textarea>
                                        </div>
                                    </div>


                                    
                                    <div className='inputs'>
                                        <div className='input'>
                                            <input required type="file" />
                                        </div>
                                    </div>


                                    <button type="submit"> SEND PROPOSAL </button>


                                </form>
                            </div>
                        </div>
                        


                    </div>
                </div>
            </section>
        </>
    )
}

export default BecomeADistrbuter;