import './css/contactus.css';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation from react-router-dom

import Contact1 from './images/contact1_1.webp';
import Contact2 from './images/contact2_2.webp';
import Contact3 from './images/contact3_0.webp';
import ContactUsBack from './images/projectsbackground.webp';

import PageHeader from './PageHeader';

function ContactUs(){

    const location = useLocation(); // Get the current location from react-router

    useEffect(() => {
    // Scroll to the top of the page whenever the route changes
    window.scrollTo(0, 0);
    }, [location.pathname]); // Re-run this effect when the pathname changes

    return(
        <>
            <PageHeader link1="HOME" link2="CONTACT US"  title="Contact Us" img={ContactUsBack}/>

            <section className='contact-us'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='contact-info'>
                                <img src={Contact1} alt="contact image" />

                                <div className='info'>
                                <i class="las la-envelope-open-text"></i>
                                    <div>
                                        <h2>  MAKE A QUOTE </h2>
                                        <a href='mailto:info@meister-me.com'> info@meister-me.com </a>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='contact-info'>
                                <img src={Contact2} alt="contact image" />

                                <div className='info'>
                                <i class="las la-headset"></i>
                                    <div>
                                        <h2>  CALL US 24/7 </h2>
                                        <a href='tel:+966126548882'> +966 126 548 882 </a>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='contact-info'>
                                <img src={Contact3} alt="contact image" />

                                <div className='info'>
                                    <i class="las la-map-marked-alt"></i>
                                    <div>
                                        <h2> WORK STATION </h2>
                                        <a href='#'> Saudi Arabia, Jeddah. industrial district "zone 1"</a>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                            <div className='form-content'>
                                <form>
                                    <div className='inputs'>
                                        <div className='input'>
                                            <input required type="text" placeholder='Your Name' />
                                        </div>

                                        <div className='input'>
                                            <input required type="email" placeholder='Your Email' />
                                        </div>
                                    </div>


                                    <div className='inputs'>
                                        <div className='input'>
                                            <input required type="text" placeholder='Subject' />
                                        </div>
                                    </div>


                                    <div className='inputs'>
                                        <div className='input'>
                                            <textarea required placeholder='Message'> </textarea>
                                        </div>
                                    </div>


                                    <button type="submit"> SEND MESSAGE </button>


                                </form>
                            </div>
                        </div>
                        

                        <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14946551.076611191!2d55.72134382506679!3d23.851991658419408!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15e7b33fe7952a41%3A0x5960504bc21ab69b!2z2KfZhNiz2LnZiNiv2YrYqQ!5e0!3m2!1sar!2seg!4v1698422857144!5m2!1sar!2seg" width="100%" height="450"  allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>



                    </div>
                </div>
            </section>
        </>
    )
}

export default ContactUs;