import './css/services.css';

import {Swiper,SwiperSlide} from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-cube';

import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import {Autoplay,EffectCube,EffectCoverflow,Navigation,Pagination} from 'swiper';


import Service1 from './images/ser1.webp';
import Service2 from './images/ser2.webp';
import Service3 from './images/ser3.webp';
import Service4 from './images/ser4.webp';
import Service5 from './images/ser5.webp';

import ServiceBack from './images/serviceBack.webp';

function Services(){
    return(
        <>
            <section className='services'>
                <img src={ServiceBack}  alt="service section back"/>


                <div className='container-fluid'> 
                    <div className='services-content'>
                        <div className='header'> 
                            <h2> OUR SERVICES </h2>
                            <h1> Comprehensive Range Of <span> Services </span> </h1>
                        </div>
                    
                        <Swiper  grabCursor={true} slidesPerView={'auto'} navigation={{
                            nextEl: '.next-btn-2',
                            prevEl: '.prev-btn-2',
                            }} pagination={true}
                            modules={[Autoplay,EffectCoverflow,EffectCube,Navigation,Pagination]}
                            >

                            <SwiperSlide>
                                <div className='service'>
                                    <div className='top'>
                                        <i class="las la-atom"></i>
                                        <h1> Construction Chemicals </h1>

                                        <p>
                                            We are renowned for our cutting-edge construction chemicals that enhance the durability, strength, and sustainability of construction projects. Our extensive product range caters to a variety of applications, ensuring the successful execution of your projects.
                                        </p>

                                    
                                    </div>

                                    <div className='bottom'>
                                        <a href="#"> CONTACT US </a>
                                        <div className='overlay'></div>
                                        <img src={Service1} alt="service img" />
                                    </div>


                                </div>
                            </SwiperSlide>


                            <SwiperSlide>
                                <div className='service'>
                                    <div className='top'>
                                        <i class="las la-user-cog"></i>
                                        <h1> Expert Consultation </h1>

                                        <p>
                                        Meister provides expert consultation services to help you choose the right construction materials and solutions for your specific project requirements. Our knowledgeable team is dedicated to ensuring your construction needs are met efficiently.
                                        </p>

                                    
                                    </div>

                                    <div className='bottom'>
                                        <a href="#"> CONTACT US </a>
                                        <div className='overlay'></div>
                                        <img src={Service2} alt="service img" />
                                    </div>


                                </div>
                            </SwiperSlide>


                            <SwiperSlide>
                                <div className='service'>
                                    <div className='top'>
                                    <i class="las la-shield-alt"></i>
                                        <h1>  Quality Assurance </h1>

                                        <p>
                                        We take pride in adhering to stringent international quality standards, guaranteeing that our construction materials are on par with the best in the industry. Our commitment to quality assurance ensures the reliability and longevity of your structures.
                                        </p>

                                    
                                    </div>

                                    <div className='bottom'>
                                        <a href="#"> CONTACT US </a>
                                        <div className='overlay'></div>
                                        <img src={Service3} alt="service img" />
                                    </div>


                                </div>
                            </SwiperSlide>



                            <SwiperSlide>
                                <div className='service'>
                                    <div className='top'>
                                    <i class="las la-puzzle-piece"></i>
                                        <h1> Customized Solutions </h1>

                                        <p>
                                        We understand that each project is unique, and our team excels in tailoring solutions to your specific needs. Whether you require custom product formulations, delivery schedules, or technical support, we've got you covered.
                                        </p>

                                    
                                    </div>

                                    <div className='bottom'>
                                        <a href="#"> CONTACT US </a>
                                        <div className='overlay'></div>
                                        <img src={Service4} alt="service img" />
                                    </div>


                                </div>
                            </SwiperSlide>


                            <SwiperSlide>
                                <div className='service'>
                                    <div className='top'>
                                        <i class="las la-users"></i>
                                        <h1> Partnerships </h1>

                                        <p>
                                        Leveraging our affiliation with Batterjee Holding Company and strategic partnerships with international industry leaders, we provide access to distinctive industrial capabilities and superior technology, resulting in the manufacturing of only the highest-quality construction materials.
                                        </p>

                                    
                                    </div>

                                    <div className='bottom'>
                                        <a href="#"> CONTACT US </a>
                                        <div className='overlay'></div>
                                        <img src={Service5} alt="service img" />
                                    </div>


                                </div>
                            </SwiperSlide>


                        </Swiper>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Services;