import './App.css';
import { Routes, Route, useLocation } from "react-router-dom";
import React, { useState, useRef } from "react";



import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

import Navbar from './components/Navbar';
import Landing from './components/Landing';
import About from './components/About';
import Services from './components/Services';
import Numbers from './components/Numbers';
import Questions from './components/Questions';
import OurProjects from './components/OurProjects';
import Footer from './components/Footer';
import OurVision from './components/OurVision';
import OurMission from './components/OurMission';
import ContactUs from './components/ContactUs';
import OurProjectsPage from './components/OurProjectsPage';
import Product from './components/Product';

import ExploreProjects from './components/ExploreProjects';
import BecomeADistrbuter from './components/BecomeADistrbuter';
import Project from './components/Project';


//pages 





function App() {


return (
    <div className="App">

            <Routes>
            

            <Route path="/" element={
                    <>
                                <Navbar/>

                        <Landing />
                        <About />
                        <Services />
                        <OurProjects />
                        <ExploreProjects />
                        <Questions />
                        <Footer  />

                    </>
                } />


            <Route path="/about-us" element={
                    <>
                                <Navbar/>

                        <OurMission/>
                        <OurVision />
                        <Services />
                        <Footer  />

                    </>
                } />

            <Route path="/our-projects" element={
                    <>
                                <Navbar/>

                        <OurProjectsPage/>
                        <Footer  />

                        
                    </>
                } />

                <Route path="/product" element={
                    <>
                                <Navbar/>

                        <Product/>
                        <Footer  />

                        
                    </>
                } />

                
            <Route path="/project" element={
                    <>
                                <Navbar/>

                        <Project />
                        <Footer  />

                        
                    </>
                } />

                            
            <Route path="/project" element={
                                <>
                                            <Navbar/>

                                    <Project/>
                                    <Footer  />

                                    
                                </>
                } />

            <Route path="/contact-us" element={
                                <>
                                                                            <Navbar/>

                                    <ContactUs/>
                                    <Footer  />

                                </>
                            } />


<Route path="/become-a-distributer" element={
                                <>
                                                                            <Navbar/>

                                    <BecomeADistrbuter/>
                                    <Footer  />

                                </>
                            } />
        </Routes>


        
    </div>
);
}

export default App;
