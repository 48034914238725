import './css/product.css';

import PageHeader from './PageHeader';
import ProjectImg from './images/projects/50_0.webp';

import projectBack from './images/projects/50_0.webp';
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from '../config/index';

function Project(){

    const navigate = useNavigate();
const location = useLocation();
const queryParams = new URLSearchParams(location.search);
const id = queryParams.get('id');

const [project, setProject] = useState(null);

const getProject = (itemId) => {
    let table = "projects";
    axios.post('get-item', { itemId, table })
    .then((res) => {
        if (res.data.message) {
        console.log('Product not found');
        } else {
        setProject(res.data);
        }
    })
    .catch((err) => console.log(err));
};

useEffect(() => {
    getProject(id);
}, [id]);

if (!project) {
    return <div>Loading...</div>;
}

    return(
        <>
    <PageHeader
        link1="HOME"
        link2={project.name}
        title={project.name}
        img={`./uploads/projects/${project.img}`} // Use the first image in the array
    />

            <section className='prodcut'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='product-img'>
                                <img src={`./uploads/projects/${project.img}`}/>
                                
                            </div>
                        </div>



                        <div className='col-lg-8 col-md-8 col-sm-12 col-12'>
                            <div className='product-desc'>
                                <h1> {project.name} </h1>

                                <p>  {project.para} </p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Project;