import './css/exploreprojects.css';

import ProjectImg1 from './images/projects/project1.webp';
import ProjectImg2 from './images/projects/project2.webp';
import ProjectImg3 from './images/projects/project3.webp';
import ProjectImg4 from './images/projects/50_0.webp';
import ProjectImg5 from './images/projects/51_1.webp';


import axios from '../config/index';
import { useState } from 'react';
import { useEffect,useRef  } from "react";

function ExploreProjects(){

    
    const [Allprojects, setAllProjects] = useState([]);

    const getAllProjects = () => {
        axios
            .post("AllItems", {
            table: "projects",
            })
            .then((res) => {
            if (res.data) {
                setAllProjects(res.data);
            } else {
                console.log("Error happened");
            }
            })
            .catch((err) => console.log(err));
        };
    
        useEffect(() => {
            getAllProjects();
        }, []);


        
    return(
        <>
            <section className='explore-projects'>
                <div className='header'>
                    <h1> Explore <span>Latest </span> Projects Gallery </h1>
                </div>

                <div className='container-fluid'>
                    <div className='row'>
                    {Allprojects && Allprojects.length > 0 && Allprojects.map((project, index) => (
                <div key={index} className={`col-lg-${index % 3 === 0 ? '6' : '3'} col-md-6 col-sm-12 col-12`}>
                    <div className='project-img'>
                    <img src={`./uploads/projects/${project.img}`} alt="project image" />
                    <h3>{project.name}</h3>
                    </div>
                </div>
                ))}



                    </div>
                </div>
            </section>
        </>
    )
}

export default ExploreProjects;