import './css/ourprojectspage.css';
import PageHeader from './PageHeader';

import PageHeaderMission from './images/pageheaders/achievments.webp';
import Project1 from './images/projects/project1.webp';
import Project2 from './images/projects/project2.webp';


import {Swiper,SwiperSlide} from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-cube';

import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import {Autoplay,EffectCube,EffectCoverflow,Navigation,Pagination} from 'swiper';


import axios from '../config/index';
import { useState } from 'react';
import { useEffect,useRef  } from "react";
import { Link as RouterLink } from 'react-router-dom';


import CompanyLogo1 from './images/companies/1.jpeg';
import CompanyLogo2 from './images/companies/2.jpeg';
import CompanyLogo3 from './images/companies/3.jpeg';
import CompanyLogo4 from './images/companies/4.jpeg';
import CompanyLogo5 from './images/companies/5.jpeg';
import CompanyLogo6 from './images/companies/6.jpeg';
import CompanyLogo7 from './images/companies/7.jpeg';
import CompanyLogo8 from './images/companies/8.jpeg';
import CompanyLogo9 from './images/companies/9.jpeg';
import CompanyLogo10 from './images/companies/10.jpeg';
import CompanyLogo11 from './images/companies/11.jpeg';
import CompanyLogo12 from './images/companies/12.jpeg';





function OurProjectsPage(){

    
    const [Allprojects, setAllProjects] = useState([]);

    const getAllProjects = () => {
        axios
            .post("AllItems", {
            table: "projects",
            })
            .then((res) => {
            if (res.data) {
                setAllProjects(res.data);
            } else {
                console.log("Error happened");
            }
            })
            .catch((err) => console.log(err));
        };
    
        useEffect(() => {
            getAllProjects();
        }, []);

    return(
        <>
            <PageHeader link1="HOME" link2="ACHIEVEMENTS"  title="ACHIEVEMENTS" img={PageHeaderMission}/>
            <section className='our-projects-page'>
                <div className='container-fluid'>
                    <div className='row'>

                    <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                            <div className='header'>
                                <div className='text'>
                                    <h2> OUR  PROJECTS </h2>
                                </div>

                            </div>
                        </div>

                        {Allprojects && Allprojects.length > 0 && Allprojects.map((project, index) => (
                        <div key={index} className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='project'>
                            <img src={`./uploads/projects/${project.img}`} alt="project image" />
                            <div className='text'>
                                <div>
                                <span><i className="las la-map-marker"></i> {project.location}</span>
                                <span><i className="las la-calendar"></i> {project.date}</span>
                                </div>
                                <h1>{project.name}</h1>
                                <RouterLink to={`/project?id=${project.id}`}>READ MORE</RouterLink>
                            </div>
                            </div>
                        </div>
                        ))}



                        
                        <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                            <div className='header'>
                                <div className='text'>
                                    <h2> APPROVALS </h2>
                                </div>

                            </div>


                            <div className='companies-logo'>
                            <Swiper  grabCursor={true} slidesPerView={'auto'} navigation={{
                                nextEl: '.next-btn',
                                prevEl: '.prev-btn',
                                }} pagination={true}
                                autoplay={{
                                    delay: 4000,
                                    disableOnInteraction: false,
                                }}
                                modules={[Autoplay,EffectCoverflow,EffectCube,Navigation,Pagination]}
                                >
                                <SwiperSlide>
                                    <div className='company'>
                                        <img src={CompanyLogo1} alt="meister aproval" />
                                    </div>
                                </SwiperSlide>

                                
                                <SwiperSlide>
                                    <div className='company'>
                                        <img src={CompanyLogo2} alt="meister aproval" />
                                    </div>
                                </SwiperSlide>

                                

                                <SwiperSlide>
                                    <div className='company'>
                                        <img src={CompanyLogo3} alt="meister aproval" />
                                    </div>
                                </SwiperSlide>

                                
                                <SwiperSlide>
                                    <div className='company'>
                                        <img src={CompanyLogo4} alt="meister aproval" />
                                    </div>
                                </SwiperSlide>


                                
                                <SwiperSlide>
                                    <div className='company'>
                                        <img src={CompanyLogo5} alt="meister aproval" />
                                    </div>
                                </SwiperSlide>

                                
                                <SwiperSlide>
                                    <div className='company'>
                                        <img src={CompanyLogo6} alt="meister aproval" />
                                    </div>
                                </SwiperSlide>



                                
                                <SwiperSlide>
                                    <div className='company'>
                                        <img src={CompanyLogo7} alt="meister aproval" />
                                    </div>
                                </SwiperSlide>


                                
                                <SwiperSlide>
                                    <div className='company'>
                                        <img src={CompanyLogo8} alt="meister aproval" />
                                    </div>
                                </SwiperSlide>


                                
                                <SwiperSlide>
                                    <div className='company'>
                                        <img src={CompanyLogo9} alt="meister aproval" />
                                    </div>
                                </SwiperSlide>


                                
                                <SwiperSlide>
                                    <div className='company'>
                                        <img src={CompanyLogo10} alt="meister aproval" />
                                    </div>
                                </SwiperSlide>



                                
                                <SwiperSlide>
                                    <div className='company'>
                                        <img src={CompanyLogo11} alt="meister aproval" />
                                    </div>
                                </SwiperSlide>


                                
                                <SwiperSlide>
                                    <div className='company'>
                                        <img src={CompanyLogo12} alt="meister aproval" />
                                    </div>
                                </SwiperSlide>


                            </Swiper>

                            </div>
                        </div>


                    </div>
                </div>
            </section>
        </>
    )
}


export default OurProjectsPage;