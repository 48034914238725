import './css/ourvision.css';
import VisionImg from './images/visionback.webp';

function OurVision(){
    return(
        <>
            <section className='our-vision'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='vision-img'>
                                <img src={VisionImg} alt="vision section img" />
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='vision-text'>
                                <h2>  Guiding a Bright Future </h2>
                                <h1> Charting a <span> Vision </span> for a Bright Tomorrow </h1>
                                <p> At Meister, we empower your construction projects with our unwavering commitment to quality, continuous innovation, and global expertise. Choose Meister, and build with confidence, knowing that we're here to meet your unique construction needs with creative, dependable solutions. Join us in shaping the future of the construction industry.
                                </p>

                                <div className='main-points'>
                                    <div>
                                        <i class="las la-user-alt"></i>
                                        <h3> Customer Focus </h3>
                                    </div>
                                    <div>
                                        <i class="las la-paste"></i>
                                        <h3> Contract Alignment </h3>
                                    </div>
                                    <div>
                                        <i class="lar la-calendar-check"></i>
                                        <h3> Standard Compliance </h3>
                                    </div>
                                    <div>
                                        <i class="las la-hand-holding-usd"></i>
                                        <h3> Cost Efficiency </h3>
                                    </div>
                                    <div>
                                        <i class="las la-clock"></i>
                                        <h3> Timely Delivery </h3>
                                    </div>
                                    <div>
                                        <i class="las la-magic"></i>
                                        <h3> Wish Fulfillment </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default OurVision;   