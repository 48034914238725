import './css/ourprojects.css';

import LetsTalkImage from './images/letsbackground.webp';

import Project1 from './images/projects/project1.webp';
import Project2 from './images/projects/project2.webp';
import Project3 from './images/projects/project3.webp';

import axios from '../config/index';
import { useState } from 'react';
import { useEffect,useRef  } from "react";
import { Link as RouterLink } from 'react-router-dom';


function OurProjects(){

    const [Allprojects, setAllProjects] = useState([]);

    const getAllProjects = () => {
        axios
            .post("AllItems", {
            table: "projects",
            })
            .then((res) => {
            if (res.data) {
                setAllProjects(res.data);
            } else {
                console.log("Error happened");
            }
            })
            .catch((err) => console.log(err));
        };
    
        useEffect(() => {
            getAllProjects();
        }, []);

    return(
        <>
            <section className='our-projects'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                            <div className='header'>
                                <div className='text'>
                                    <h2> OUR RECENT PROJECTS </h2>
                                    <h1> Meeting <span> Saudi Market </span> Demands with Unique Solutions </h1>
                                </div>

                                <RouterLink to="/our-projects"> View All Projects </RouterLink>
                            </div>
                        </div>


                        <div className='col-lg-8 col-md-8 col-sm-12 col-12'>
                        <div className='row'>
                            {Allprojects && Allprojects.length > 0 && Allprojects.slice(0, 2).map((project, index) => (
                            <div key={index} className='col-lg-12 col-md-12 col-sm-12 col-12'>
                                <div className='project'>
                                <img src={`./uploads/projects/${project.img}`} alt="project image" />
                                <div className='text'>
                                    <div>
                                    <span><i className="las la-map-marker"></i> {project.location}</span>
                                    <span><i className="las la-calendar"></i> {project.date}</span>
                                    </div>
                                    <h1>{project.name}</h1>
                                    <RouterLink to={`/project?id=${project.id}`}>READ MORE</RouterLink>
                                </div>
                                </div>
                            </div>
                            ))}
                        </div>
                        </div>

                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                        {Allprojects && Allprojects.length > 0 && Allprojects.slice(2, 3).map((project, index) => (
                            <div key={index} className='project special'>
                            <img src={`./uploads/projects/${project.img}`} alt="project image" />
                            <div className='text'>
                                <div>
                                <span><i className="las la-map-marker"></i> {project.location}</span>
                                <span><i className="las la-calendar"></i> {project.date}</span>
                                </div>
                                <h1>{project.name}</h1>
                                <RouterLink to={`/project?id=${project.id}`}>READ MORE</RouterLink>
                            </div>
                            </div>
                        ))}
                        </div>



                    </div>
                </div>
            </section>
        </>
    )
}

export default OurProjects;