import './css/navbar.css';

import Logo from './images/logo.png';
import LogoMeister from './images/meister.png';

import { useState } from 'react';

import { useEffect,useRef  } from "react";
import $ from 'jquery';

import { Link as RouterLink } from 'react-router-dom';


import productImg1 from './images/products/Tile-Adhesive-801-167x300.png';
import productImg2 from './images/products/Tile-Adhesive-806-167x300.png';


import axios from '../config/index';


function Navbar(props){


    const [AllCategories, setAllCategories] = useState([]);

    const [AllProducts, setAllProducts] = useState([]);

        
    const getAllCategories = () => {
        axios
            .post("AllItems", {
            table: "categories",
            })
            .then((res) => {
            if (res.data) {
                setAllCategories(res.data);
            } else {
                console.log("Error happened");
            }
            })
            .catch((err) => console.log(err));
        };
    


        const getAllProducts = () => {
            axios
                .post("AllItems", {
                table: "products",
                })
                .then((res) => {
                if (res.data) {
                    setAllProducts(res.data);
                } else {
                    console.log("Error happened");
                }
                })
                .catch((err) => console.log(err));
            };
        

        const getProductsForCategory = (categoryId) => {
            return AllProducts.filter((product) => product.cat_id === categoryId);

        };
            

        
        useEffect(() => {
            getAllProducts();
            getAllCategories();
        }, []);

    
    const [solutionsActive, setSolutionsActive] = useState(false); // State to track search content
    const [searchActive, setSearchActive] = useState(false); // State to track search content

    const toggleSolutions = () => {
        setSolutionsActive((prevState) => !prevState);
        if (searchActive) {
            setSearchActive(false);
        }
        if ($(window).width() < 950) {
            $(".nav-elements").toggleClass("active", !solutionsActive);
        }
    };


    const toggleSearch = () => {
        setSearchActive((prevState) => !prevState);
        if (solutionsActive) {
            setSolutionsActive(false);
        }
        if ($(window).width() < 950) {
            $(".nav-elements").toggleClass("active", !searchActive);
        }
    };
    
    

    const [servicesNavActive, setServicesNavActive] = useState(false);

    const toggleServicesNav = () => {
        setServicesNavActive(prevState => !prevState);
    };



    
    useEffect(() => {
        $("#open-nav").on("click", function() {
            $(".nav-elements").addClass("active");
            setSolutionsActive(false);
            setSearchActive(false);
        });

        $("#close-nav").on("click", function() {
            $(".nav-elements").removeClass("active");
        });

        if ($(window).width() < 950) {
            $(".nav-elements ul li").on("click", function() {
                if($(this).hasClass("open-sec")){

                }else{
                    $(".nav-elements").removeClass("active");

                }
            });
        }




    const loadingElement = document.getElementById("loading");
    if (loadingElement) {
        loadingElement.remove();
    }
    }, []);
    
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredProducts, setFilteredProducts] = useState([]);
    const searchActiveQuery = searchQuery !== '';
    useEffect(() => {
        // Filter products based on the search query
        if (searchQuery.trim() !== '') {
        const filtered = AllProducts.filter(product =>
            product.header.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredProducts(filtered);
        } else {
        // If no search query, set filtered products to an empty array
        setFilteredProducts([]);
        }
    }, [searchQuery]);
    
    
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };



    useEffect(() => {
        const handleResize = () => {
            if ($(window).width() >= 950) {
                $(".nav-elements").removeClass("active");
            }
        };
    
        $(window).on("resize", handleResize);
    
        return () => {
            $(window).off("resize", handleResize);
        };
    }, []);
    

    return(
        <>
    
            <nav className={`${props.className}`}>
                <div className='container-fluid'>
                    <div className='nav-content'>
                        <div className='logo'>
                            <RouterLink to="/">
                                <img src={LogoMeister} alt="uccc logo" />
                            </RouterLink>

                        </div>
                        
                        <div className='nav-inner-content'>


                            <div className='bottom'>
                            <button id="open-nav"> <i className="las la-bars"></i> </button>
                            <div className="nav-elements">
                            <button id="close-nav"> <i className="las la-times"></i> </button>
                                    <ul>
                                        <li>
                                            <RouterLink to="/">
                                                HOME
                                            </RouterLink>
                                        </li>

                                        <li>
                                            <RouterLink to="/about-us">
                                                ABOUT US
                                            </RouterLink>
                                        </li>

                                        <li>
                                            <RouterLink onClick={toggleSolutions}>
                                                OUR SOLUTIONS
                                            </RouterLink>
                                        </li>

                                        
                                        <li>
                                            <RouterLink to="/our-projects">
                                                ACHIEVEMENTS
                                            </RouterLink>
                                        </li>


                                        <li>
                                            <RouterLink to="/become-a-distributer">
                                                BECOME A DISTRIBUTER
                                            </RouterLink>
                                        </li>


                                        <li>
                                            <RouterLink to="/contact-us">
                                                CONTACT US
                                            </RouterLink>
                                        </li>
                                        
                                    </ul>
                                </div>


                                <div className='search'>
                                <button onClick={toggleSearch}>
                                        <i class="las la-search"></i>
                                    </button>
                                </div>

                                <div className='nav-contact'>
                                    <a href="tel:+966126548882">
                                        <i class="las la-phone-volume"></i>
                                    </a>

                                    <a href="https://wa.me/+966126548882">
                                        Get A Quote
                                        <i class="las la-paper-plane"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>

            <div className={`search-content ${searchActive ? 'active' : ''}`}>
                <input
                    placeholder='Search ...'
                    required
                    name="searchproducts"
                    value={searchQuery}
                    onChange={handleSearchChange}
                />
                <div className='products'>
                {filteredProducts.map((product, index) => {
                    // Convert the images string to an array
                    const imageArray = product.images.split(',');

                    return (
                        <div key={index} className='product'>
                        <RouterLink onClick={toggleSearch} to={`/product?id=${product.id}`}>
                            <img src={`./uploads/products/${imageArray.length > 0 ? imageArray[0] : ''}`} alt={`product img ${index}`} />
                            <h1>{product.header}</h1>
                        </RouterLink>
                        </div>
                    );
                    })}
                </div>
                </div>

                <div className={`our-solutions-pages ${solutionsActive ? 'active' : ''}`}>
                <div className='content'>
                    <ul className='main-menu'>
                    {AllCategories && AllCategories.length > 0 && AllCategories.map((category) => (
                        <li key={category.id}>
                        <a href="#">
                            {category.name} <i className="las la-arrow-right"></i>
                        </a>
                        <ul className='sub-menu'>
                            {getProductsForCategory(category.id).map((product) => (
                            <li key={product.id}>
                                <RouterLink to={`/product?id=${product.id}`} onClick={toggleSolutions}>
                                {product.header}
                                </RouterLink>
                            </li>
                            ))}
                        </ul>
                        </li>
                    ))}
                    </ul>
                </div>
                </div>

        </>
    )
}

export default Navbar;