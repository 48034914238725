import './css/ourmission.css';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation from react-router-dom


import PageHeaderMission from './images/pageheaders/aboutus.webp';
import LargeMissionImg from './images/lgchoose.webp';
import SmallMissionImg from './images/smchoose.webp';
import PageHeader from './PageHeader';



function OurMission(){

    const location = useLocation(); // Get the current location from react-router

    useEffect(() => {
    // Scroll to the top of the page whenever the route changes
    window.scrollTo(0, 0);
    }, [location.pathname]); // Re-run this effect when the pathname changes



    return(
        <>
            <PageHeader link1="HOME" link2="ABOUT US"  title="About Us" img={PageHeaderMission}/>
            <section className='our-mission'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='our-mission-img'>
                                <img src={LargeMissionImg} alt="our-mission-img" />
                                <img src={SmallMissionImg} alt="small-mission-img" />
                            </div>
                        </div>


                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className="our-mission-text">
                                <h2>  Choose Meister, Build with Confidence </h2>
                                <h1>Meister is a <span> German term </span> that means <span>  master </span>; one with extensive theoretical knowledge and practical skills in a <span> profession </span>, <span> business </span>, or <spna> activity </spna> </h1>

                                <p> 
                                Therefore, it is our quest to guarantee mastery in production, safety, working conditions, and delivery.
                                </p>
                                <h2>  Meister is a subsidiary of Batterjee Holding Company, which was formed in 2008,</h2>
                                <p> Batterjee Holding Company has definitive expertise in the industrial realm. It is known for its specializations in pharmaceuticals, medical equipment, baby products, and foods and plastics, among others. This has allowed us to become a market leader in the field of construction materials and construction-related industries by building additional partnerships with international companies that provide only the most excellent products</p>

                                <p>  
                                Our partners ensure distinctive industrial capabilities, superior technology, and the manufacturing of only the highest quality construction materials. We adhere to stringent international standards of quality and production, and our materials are comparable to competitors worldwide.
                                </p>
                                
                                <ul>
                                    <li>
                                        <i class="las la-check-circle"></i>
                                        Creative and Dependable Solutions
                                    </li>
                                    <li>
                                        <i class="las la-check-circle"></i>
                                        Commitment to Quality
                                    </li>
                                    <li>
                                        <i class="las la-check-circle"></i>
                                        Continuous Improvement
                                    </li>
                                    <li>
                                        <i class="las la-check-circle"></i>
                                        Global Expertise
                                    </li>

                                    <li>
                                        <i class="las la-check-circle"></i>
                                        International Standards
                                    </li>


                                    <li>
                                        <i class="las la-check-circle"></i>
                                        Innovation
                                    </li>
                                </ul>

                                <a href='https://wa.me/+966126548882'> GET IN TOUCH</a>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default OurMission;