import './css/landing.css';

import LandingVid from './videos/landingvid.mp4';
import { Link as RouterLink } from 'react-router-dom';

function Landing(){
    return(
        <>
            <section className='landing'>
                    <div className='landing-content'>
                        <video autoPlay muted loop src={LandingVid} alt="landing video"></video>
                        <div className='overlay'></div>

                        <div className='text'>
                            <h2> Nesma Batterjee Company </h2>
                            <h1> We Are <span>Creative</span> , Honest , <span>Dependable</span> </h1>
                            <h3> Innovation is our lifeblood </h3>

                            <RouterLink to="/our-projects"> ACHIEVEMENTS <i class="las la-angle-double-right"></i></RouterLink>
                        </div>

                        <div class='text-overlay-1 '></div>
                        <div class='text-overlay-2 '></div>
                    </div>
            </section>
        </>
    )
}


export default Landing;