import './css/footer.css';
import { Link as RouterLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom'; // Import useLocation from react-router-dom

import React, { useEffect } from 'react';

import FooterBack from './images/footerback.webp';
import Logo from './images/logo.png';


function Footer(props){

    
    const location = useLocation(); // Get the current location from react-router

    useEffect(() => {
    // Scroll to the top of the page whenever the route changes
    window.scrollTo(0, 0);
    }, [location.pathname]); // Re-run this effect when the pathname changes



    return(
        <>
     
            <footer className={`${props.className}`}>
                <img src={FooterBack} alt="footer background" />
                <div className='overlay'></div>

                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='footer-content border-right'>
                                
                                <h1> Join Forces for Something Remarkable! </h1>

                                <div className='links'>
                                    <a href="#">
                                        OUR SOLUTIONS
                                    </a>

                                    <a href="#">
                                        CONTACT US
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='footer-content'>
                                <div className='contact-info'>
                                    <div>
                                        <h1> ADDRESS </h1>
                                        <a href="#">
                                            Saudi Arabia, Jeddah.
                                        </a>
                                        <a href="#">
                                            industrial district "zone 1"
                                        </a>

                                        <ul>
                                            <li>
                                                <a href="#">
                                                    <i class="lab la-facebook-f"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <i class="lab la-twitter"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <i class="lab la-linkedin-in"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <i class="lab la-youtube"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>


                                    <div>
                                        <h1> SAY HELLO </h1>
                                        <a href="#">
                                            <i class="las la-envelope"></i>
                                            info@meister-me.com
                                        </a>

                                        <a href="#">
                                            <i class="las la-phone-volume"></i>
                                            +966 126 548 882
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>

                <ul className="footer-links">
                                        <li>
                                            <RouterLink to="/">
                                                HOME
                                            </RouterLink>
                                        </li>

                                        <li>
                                            <RouterLink to="/about-us">
                                                ABOUT US
                                            </RouterLink>
                                        </li>

                                        
                                        <li>
                                            <RouterLink to="/our-projects">
                                                ACHIEVEMENTS
                                            </RouterLink>
                                        </li>


                                        <li>
                                            <RouterLink to="/why-us">
                                                BECOME A DISTRIBUTER
                                            </RouterLink>
                                        </li>


                                        <li>
                                            <RouterLink to="/contact-us">
                                                CONTACT US
                                            </RouterLink>
                                        </li>
                                        
                                    </ul>

                                        
                                <div className='logo'>
                                    <img src={Logo} alt='logo nesma' />
                                </div>
                
            
                <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                            <div className='copy-right'>
                                <h3> &copy; Nesma Batterjee. All Right Reserved by    <a href="#">Meister</a> </h3>
                            </div>
                        </div>
            </footer>

            
        </>
    )
}

export default Footer;