import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from '../config/index';
import './css/product.css';
import PageHeader from './PageHeader';


function Product() {
const navigate = useNavigate();
const location = useLocation();
const queryParams = new URLSearchParams(location.search);
const id = queryParams.get('id');

const [product, setProduct] = useState(null);

const getProduct = (itemId) => {
    let table = "products";
    axios.post('get-item', { itemId, table })
    .then((res) => {
        if (res.data.message) {
        console.log('Product not found');
        } else {
        setProduct(res.data);
        }
    })
    .catch((err) => console.log(err));
};

useEffect(() => {
    getProduct(id);
}, [id]);

if (!product) {
    return <div>Loading...</div>;
}

const imageArray = product.images.split(',');
const specificationArray = product.specifications.split('/.');

const downloadFile = (fileUrl) => {
    // Create a link element
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = ''; // You can specify the filename here if needed
    document.body.appendChild(link);

    // Trigger the click event on the link
    link.click();

    // Remove the link element from the document
    document.body.removeChild(link);
};

const downloadTechnicalSheet = () => {
    // Replace 'your-server-url' with the actual URL where your technical sheet is stored
    const technicalSheetUrl = `./uploads/tech/${product.tech_pdf}`;
    downloadFile(technicalSheetUrl);
};

const downloadSafetySheet = () => {
    // Replace 'your-server-url' with the actual URL where your safety sheet is stored
    const safetySheetUrl = `./uploads/safety/${product.safety_pdf}`;
    downloadFile(safetySheetUrl);
};

return (
    <>
    <PageHeader
        link1="HOME"
        link2={product.header}
        title={product.header}
        img={`./uploads/products/${imageArray[0]}`} // Use the first image in the array
    />

    <section className='prodcut'>
        <div className='container-fluid'>
        <div className='row'>
            <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
            <div className='product-img'>
                <img src={`./uploads/products/${imageArray[0]}`} alt={product.header} />
                <div className='small-imgs'>
                {imageArray.map((img, index) => (
                    <img key={index} src={`./uploads/products/${img}`} alt={`Product ${index + 1}`} />
                ))}
                </div>
            </div>
            </div>

            <div className='col-lg-8 col-md-8 col-sm-12 col-12'>
            <div className='product-desc'>
                <h1>{product.header}</h1>
                <h5>{product.sec_header}</h5>
                <p>{product.para}</p>

                <a href="#"> CONTACT US </a>

                <ul>
                {specificationArray.map((spe, index) => (
                    <li key={index}>
                    <i className="las la-check-double"></i>
                    {spe}
                    </li>
                ))}
                </ul>

                <button className='download-pdf' onClick={downloadTechnicalSheet}>
                Product Technical Sheet <i className="las la-save"></i>
                </button>

                <button className='download-pdf' onClick={downloadSafetySheet}>
                Product Safety Sheet <i className="las la-save"></i>
                </button>
            </div>
            </div>
        </div>
        </div>
    </section>
    </>
);
}

export default Product;
