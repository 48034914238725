import React, { useState } from 'react';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';
import './css/numbers.css';

function Numbers() {
const [countingStarted, setCountingStarted] = useState(false);
const [inViewRef, inView] = useInView({ triggerOnce: true });

// When the component is in view, start the counting animation
if (inView && !countingStarted) {
    setCountingStarted(true);
}

return (
    <div className="numbers" ref={inViewRef}>
    <div className="container-fluid">
        <div className="numbers-content">
        <div className="header">
            <div className="left">
            <h2>We believe in transparency and accountability</h2>
            <h1>At Meister, our commitment to excellence and dedication to the construction industry are reflected in our numbers</h1>
            </div>

            <div className="right">
            <a href="tel:+966126548882">Make An Appointment <i className="las la-arrow-right"></i></a>
            </div>
        </div>

        <div className="numbers-content-2">
            <div className="number">
            <i className="las la-users"></i>
            <div>
                <h1>
                {countingStarted ? (
                    <CountUp start={0} end={8155} duration={3} separator="," />
                ) : (
                    '0'
                )}{' '}
                </h1>
                <h2>Satisfied Customers</h2>
            </div>
            </div>

            <div className="number">
            <i className="las la-users-cog"></i>
            <div>
                <h1>
                {countingStarted ? (
                    <CountUp start={0} end={912} duration={2} separator="," />
                ) : (
                    '0'
                )}{' '}
                </h1>
                <h2>Expert Members</h2>
            </div>
            </div>

            <div className="number">
            <i className="las la-tools"></i>
            <div>
                <h1>
                {countingStarted ? (
                    <CountUp start={0} end={621} duration={2} separator="," />
                ) : (
                    '0'
                )}{' '}
                </h1>
                <h2>Modern Equipment</h2>
            </div>
            </div>

            <div className="number">
            <i className="las la-project-diagram"></i>
            <div>
                <h1>
                {countingStarted ? (
                    <CountUp start={0} end={312} duration={2} separator="," />
                ) : (
                    '0'
                )}{' '}
                </h1>
                <h2>Completed Projects</h2>
            </div>
            </div>
        </div>
        </div>
    </div>
    </div>
);
}

export default Numbers;
