import './css/about.css';

import AboutImage from './images/largeabout_0.webp';
import aboutSmImage from './images/smallabout_1.webp';
import AboutTextImage from './images/abouttext.webp';

import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';


function About(){

    useEffect(() => {
        AOS.init({
            duration: 700,
        });
    }, [])


    return(
        <>
            <section className='about'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='about-img'>
                                <img  data-aos="fade-left" src={AboutImage}  alt="about us image"/>
                                <div  data-aos="fade-left" className='overlay'></div>

                                <div  data-aos="fade-left" className='years'>
                                    <div>
                                        <div className='sm-overlay'></div>
                                        <span> 2008 </span>
                                        <img src={aboutSmImage} alt="about us small image" />
                                    </div>
                                    <h2> Batterjee Holding Company</h2>
                                </div>
                            </div>
                        </div>


                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='about-text'>
                                <h2  data-aos="fade-right"> Meister is a subsidiary of Batterjee Holding Company, which was formed in 2008 </h2>
                                <h1 data-aos="fade-right"> <span>Meister</span> is a leading global provider of construction chemicals, <span> dedicated </span> to the success of its clients and the Structure heritage </h1>

                                <p data-aos="fade-right">We are creative, honest, dependable, and always improving to develop cutting-edge construction chemicals that meet the needs of our clients in a sustainable and cost-effective way. </p>
                                <p data-aos="fade-right"> Our commitment to quality and creative solutions sets us apart from our competitors. We constantly look for new ways to improve our products and services, and we are committed to meeting the needs of our clients. </p>
                                <p data-aos="fade-right"> We are proud to have played a key role in the development of Structure's innovative construction chemicals, and we are committed to upholding the high standards that have been set for us </p>


                                <ul >
                                    <li data-aos="fade-right">
                                        <i class="las la-check-circle"></i>
                                        Exceptional Quality: Meister's commitment to stringent international quality standards ensures that our construction materials are of the highest quality, comparable to the best in the world
                                    </li>
                                    <li data-aos="fade-right">
                                    <i class="las la-check-circle"></i>
                                    Industry Expertise: As a subsidiary of Batterjee Holding Company, with a strong track record in pharmaceuticals, medical equipment, and more, Meister leverages this expertise to excel in construction-related industries
                                    </li>

                                    <li data-aos="fade-right">
                                    <i class="las la-check-circle"></i>
                                    Global Partnerships: Meister has forged partnerships with international companies, providing access to cutting-edge technology and distinctive industrial capabilities to deliver superior products
                                    </li>

                                    <li data-aos="fade-right">
                                    <i class="las la-check-circle"></i>
                                    Safety and Compliance: We prioritize safety in production and working conditions to meet the highest industry standards, ensuring the well-being of our workforce and the reliability of our materials
                                    </li>
                                </ul>

                                <div data-aos="fade-right" className='mission'>
                                    <img src={AboutTextImage} alt="about-text-image"/>

                                    <div>
                                        <p> We are confident that we can continue to be a leader in the construction chemicals industry for many years to come.  </p>
                                    </div>
                                </div>

                                <div data-aos="fade-right" className='apoint'>
                                    <a href="https://wa.me/+966126548882">
                                        Schedule an Appointment
                                    </a>
                                    <div className='about-contact'>
                                        <a href="tel:+966126548882">
                                            <i class="las la-phone-volume"></i>
                                        </a>
                                        
                                        <div>
                                            <h3> Call Us Now </h3>
                                            <a href="tel:+966126548882">
                                            +966 126 548 882
                                            </a>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default About;