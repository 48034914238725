import './css/pageheader.css';


function PageHeader(props){
    return(
        <>
            <section className='page-header'>
                    <div className={`page-header-content ${props.className}`}>
                        <img src={props.img}  alt="about page background"/>
                        <div className='overlay'></div>
                        <h1> {props.title} </h1>
                        <div className='navs'>
                            <a href="#">
                                {props.link1}
                            </a>
                            <span>/</span>
                            <a href="#" className='active'>
                                {props.link2}
                            </a>
                        </div>
                    </div>
            </section>
        </>
    )
}


export default PageHeader;